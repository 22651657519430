<footer>
  <div
    class="flex justify-center items-center h-16 bg-primary text-primary-foreground"
  >
    <p>© 2024 Ben Halverson</p>
    <ul class="flex">
      <li class="pl-2 pr-2">
        <a href="https://github.com/benhalverson">Github</a>
      </li>
      <li>
        <a href="https://linkedin.com/in/benhalverson/">Linkedin</a>
      </li>
    </ul>
  </div>
</footer>
